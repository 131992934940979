import { useAuth } from '@/contexts'
import { ISlot } from '@namuho/types'
import { useQuery } from '@tanstack/react-query'
import { getUnixTime, parseISO } from 'date-fns'

export const useSlots = () => {
    const baseUrl = 'https://api.namuho.com/slots'

    const { session } = useAuth()
    const slotsQuery = useQuery({
        queryKey: ['slots'],
        queryFn: async (): Promise<ISlot[]> => {
            const response = await fetch(`${baseUrl}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${session?.getAccessToken().getJwtToken()}`,
                },
            })

            if (!response.ok) {
                throw new Error(response.statusText)
            }

            return response.json()
        },
        enabled: !!session,
    })

    const slotsFromQuery = slotsQuery?.data || []
    const slotsFromTheFuture = slotsFromQuery?.filter((slot: ISlot) => slot.date > new Date().toISOString()) || []
    const sortedSlots = slotsFromTheFuture?.sort((a: ISlot, b: ISlot) => getUnixTime(parseISO(b.date)) - getUnixTime(parseISO(a.date))) || []

    return {
        isLoading: slotsQuery?.isLoading || false,
        slots: sortedSlots || [],
    }
}
