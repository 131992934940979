import { Link } from 'react-router-dom'
import { Button } from '@mui/material'
import { NavSection } from './NavSection'
import { useUserMatches } from '@/hooks/user/useUserMatches'

export function Tasks() {
    const { matches, isLoading } = useUserMatches()

    return (
        <>
            <NavSection title="Offene Tasks">
                <Link className="box-link" to="/go">
                    {matches.length > 0 && (
                        <Link className="inline-link" to="/dates">
                            Chatte mit deinen Dates
                        </Link>
                    )}

                    <Button fullWidth variant="contained" color="primary">
                        Neue Treffen
                    </Button>
                </Link>
            </NavSection>
        </>
    )
}
