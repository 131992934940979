/* eslint-disable react/jsx-no-undef */
import React, { useEffect, useState } from 'react'
import * as Sentry from '@sentry/react'
import { makeStyles } from 'tss-react/mui'
import { AppBar, Button, Container, Dialog, IconButton, Paper, Slide, Toolbar, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { TransitionProps } from '@mui/material/transitions'
import { IProduct, IProductV2, IQuestion, MeetingType } from '@namuho/types'
import { useReadiness } from '@/hooks/user/useReadiness'
import { ConsumableMatch } from '@/hooks/user/useUserMatches'
import { FillMissingSecrets } from './FillMissingSecrets'
import { useQueryClient } from '@tanstack/react-query'
import { useMatchSecrets } from '@/hooks/matchSecrets/useMatchSecrets'
import { useQuestions } from '@/hooks/questions/useQuestions'
import { SecretCheckbox } from './common/SecretCheckbox'
import { useProducts } from '@/hooks/products/useProducts'
import { PayPalButtons } from '@paypal/react-paypal-js'
import { useAuth } from '@/contexts'

export interface BuySecretsProps {
    match: ConsumableMatch
    open: boolean
    handleCancel: () => void
}

const useStyles = makeStyles()((theme) => ({
    root: {},
    container: {
        padding: theme.spacing(2),
    },
    paper: {
        backgroundColor: theme.palette.common.white,
        padding: theme.spacing(4),
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
    },
    buttons: {
        display: 'flex',
        gap: theme.spacing(2),
    },
    grid: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
    },
    answers: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
    },
    answerBox: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: theme.spacing(1),
        height: 'max-content',
        borderBottom: `1px dotted ${theme.palette.primary.main}`,
        paddingBottom: theme.spacing(2),
    },
    answer: {
        fontWeight: 'bold',
    },
}))

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />
})

export function BuySecrets(props: BuySecretsProps) {
    const { match, open, handleCancel } = props
    const { classes } = useStyles()
    const { session, user } = useAuth()
    const { Romantisch, Freundschaftlich } = useReadiness()
    const [loading, setIsLoading] = useState(false)
    const [product, setProduct] = useState<IProductV2 | undefined>(undefined)
    const [selectedSecrets, setSelectedSecrets] = useState<string[]>([])
    const { secretsAnswers, allSecretsBought, singleSecretsBought } = useMatchSecrets(match.id)
    const { singleSecretProduct, allSecretsProduct, isLoading: isLoadingProducts } = useProducts()
    const { secrets: secretsQuestions } = useQuestions()
    const queryClient = useQueryClient()

    const secretsAnswered = match.meetingType === MeetingType.Romantic ? Romantisch.secretsAnswered : Freundschaftlich.secretsAnswered

    const allSecretsPrice = ((allSecretsProduct?.price || 0) / 100)?.toLocaleString('de-DE', {
        style: 'currency',
        currency: 'EUR',
    })

    const singleSecretPrice = ((singleSecretProduct?.price || 0) / 100)?.toLocaleString('de-DE', {
        style: 'currency',
        currency: 'EUR',
    })

    useEffect(() => {
        if (singleSecretProduct && product === undefined) {
            setProduct(singleSecretProduct)
        }
    }, [product, singleSecretProduct])

    const handleClose = () => {
        setSelectedSecrets([])
        handleCancel()
    }

    const handleSecretsFillInCancel = () => {
        handleCancel()
    }

    const handleBuyAll = () => {
        secretsAnswers.forEach((secretAnswer) => {
            selectedSecrets.push(secretAnswer.questionId)
        })
        if (allSecretsProduct) {
            setProduct(allSecretsProduct)
        } else {
            Sentry.captureException('Missing all Secrets Product', {
                tags: {
                    section: 'Buy Secrets',
                },
                extra: {
                    boundary: true,
                },
            })
        }
    }

    const handleSecretCheck = (checked: boolean, secretId: string) => {
        if (checked) {
            setSelectedSecrets([...selectedSecrets, secretId])
        } else {
            setSelectedSecrets(selectedSecrets.filter((secret) => secret !== secretId))
        }
        setSelectedSecrets((prev) => prev.filter((id) => !singleSecretsBought.includes(id)))
        if (singleSecretProduct) {
            setProduct(singleSecretProduct)
        } else {
            Sentry.captureException('Missing single Secrets Product', {
                tags: {
                    section: 'Buy Secrets',
                },
                extra: {
                    boundary: true,
                },
            })
        }
    }

    const handleSecretsFillInSave = async () => {
        setIsLoading(true)
        await queryClient.invalidateQueries(['user', 'userData'])
        setIsLoading(false)
    }

    const createOrder = async () => {
        return fetch('https://api.namuho.com/v2/checkout/create-order', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${session?.getAccessToken().getJwtToken()}`,
            },

            body: JSON.stringify({
                productId: product?.id,
                matchId: match.id,
                secretsBought: selectedSecrets,
            }),
        })
            .then((response) => response.json())
            .then((order) => {
                return order.id
            })
    }

    const captureOrder = async (data: any) => {
        return fetch('https://api.namuho.com/v2/checkout/capture-order', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${session?.getAccessToken().getJwtToken()}`,
            },

            body: JSON.stringify({
                orderID: data.orderID,
            }),
        })
            .then((response) => response.json())

            .then((orderData) => {
                queryClient.invalidateQueries([`secrets-${match.id}`])
            })
    }

    return (
        <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Geheimnisse über {match.name} freischalten
                    </Typography>
                </Toolbar>
            </AppBar>
            <Container className={classes.container}>
                <Paper className={classes.paper}>
                    <div className={classes.content}>
                        {!secretsAnswered && (
                            <>
                                <Typography variant="body1">
                                    Um Geheimnisse deiner Dates zu erfahren/freischalten, musst du alle{' '}
                                    <strong>Geheimnisse von dir übermitteln.</strong>{' '}
                                    <a className="inline-link" rel="noreferrer" target="_blank" href="https://namuho.com/anleitung/#geheimnisse">
                                        Mehr Informationen zu Geheimnissen
                                    </a>
                                </Typography>
                                <Typography variant="h1">
                                    Um Zugang zu {match.name}s Geheimnissen zu erhalten, teil zunächst deine Geheimnisse:
                                </Typography>
                                <FillMissingSecrets
                                    onCancel={handleSecretsFillInCancel}
                                    onSave={handleSecretsFillInSave}
                                    meetingType={match.meetingType}
                                />
                            </>
                        )}
                        {secretsAnswered && !allSecretsBought && secretsQuestions && (
                            <>
                                <Typography variant="h1">{match.name} die folgenden Geheimnisse mitgeteilt</Typography>
                                <Typography variant="h2">Du kannst auf alle oder einzelne Geheimnisse zugreifen:</Typography>
                                <div className={classes.answers}>
                                    {secretsAnswers.map((secretAnswer) => (
                                        <div key={secretAnswer.questionId} className={classes.answerBox}>
                                            {!singleSecretsBought.includes(secretAnswer.questionId) && (
                                                <SecretCheckbox
                                                    checked={selectedSecrets.includes(secretAnswer.questionId)}
                                                    setChecked={handleSecretCheck}
                                                    secretId={secretAnswer.questionId}
                                                    label={
                                                        secretsQuestions.find((question: IQuestion) => question.id === secretAnswer.questionId)
                                                            ?.label || ''
                                                    }
                                                />
                                            )}
                                            {singleSecretsBought.includes(secretAnswer.questionId) && (
                                                <>
                                                    <Typography variant="body1">
                                                        {secretsQuestions.find((question: IQuestion) => question.id === secretAnswer.questionId)
                                                            ?.label || ''}
                                                    </Typography>
                                                    <Typography className={classes.answer} variant="body1">
                                                        {secretAnswer.answer}
                                                    </Typography>
                                                </>
                                            )}
                                        </div>
                                    ))}
                                </div>
                                <Button variant="contained" onClick={handleBuyAll}>
                                    alle Geheimnisse
                                </Button>

                                <Typography variant="h2">
                                    Preis pro Geheimnis: {singleSecretPrice} oder {allSecretsPrice} für alle
                                </Typography>
                                {product === singleSecretProduct && product && (
                                    <Typography variant="h2">
                                        Preis:{' '}
                                        {(((product.price || 0) * selectedSecrets.length) / 100)?.toLocaleString('de-DE', {
                                            style: 'currency',
                                            currency: 'EUR',
                                        })}
                                    </Typography>
                                )}
                                {product === allSecretsProduct && product && (
                                    <Typography variant="h2">
                                        Preis:{' '}
                                        {((product.price || 0) / 100)?.toLocaleString('de-DE', {
                                            style: 'currency',
                                            currency: 'EUR',
                                        })}
                                    </Typography>
                                )}
                                <div className={classes.buttons}>
                                    <PayPalButtons
                                        forceReRender={[selectedSecrets]}
                                        disabled={selectedSecrets.length === 0}
                                        createOrder={createOrder}
                                        onApprove={captureOrder}
                                    />
                                </div>
                            </>
                        )}
                        {allSecretsBought && secretsQuestions && (
                            <div className={classes.answers}>
                                {secretsAnswers.map((secretAnswer) => (
                                    <div key={secretAnswer.questionId} className={classes.answerBox}>
                                        <Typography variant="body1">
                                            {secretsQuestions?.find((question: IQuestion) => question.id === secretAnswer.questionId)?.label || ''}
                                        </Typography>
                                        <Typography className={classes.answer} variant="body1">
                                            {secretAnswer.answer}
                                        </Typography>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </Paper>
            </Container>
        </Dialog>
    )
}
