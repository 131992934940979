import { useAuth } from '@/contexts'
import { Gender, IMatch, IMatchTimeObject, IMatchTimeStatus, ISlot } from '@namuho/types'
import { getMatchTimeStatus } from '@namuho/helpers'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'

export interface ConsumableMatch extends IMatch {
    timeObject: IMatchTimeObject
    name: string
    gender: Gender
    otherId: string
}

export interface MatchRateObject {
    matchId: string
    rating: number
}

export interface MatchRejectObject {
    matchId: string
    comment: string
    timeSlot: ISlot
}

export interface MatchCommentObject {
    matchId: string
    comment: string
}

export const useUserMatches = () => {
    const baseUrl = 'https://api.namuho.com/users/'
    const queryClient = useQueryClient()

    const { session, user } = useAuth()
    const matchesData = useQuery({
        queryKey: ['matches', user?.id],
        queryFn: async () => {
            const response = await fetch(`${baseUrl}${user?.id}/matches`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${session?.getAccessToken().getJwtToken()}`,
                },
            })

            if (!response.ok) {
                throw new Error(response.statusText)
            }
            const rawMatches = await response.json()
            const matchesConfirmed = rawMatches.filter((match: IMatch) => match.status === 'confirmed')
            // matches sorted by date descending
            const sortedMatches = matchesConfirmed.sort((a: IMatch, b: IMatch) => {
                return new Date(b.timeSlot?.date || '').getTime() - new Date(a.timeSlot?.date || '').getTime()
            })
            // matches enriched with timeObject
            const enrichedMatches: ConsumableMatch[] = sortedMatches.map((match: IMatch) => {
                const otherUser = match.users.find((userId: string) => userId !== user?.id) || ''
                const name = match.publicInfo?.[otherUser]?.name || ''
                const gender = match.publicInfo?.[otherUser]?.gender || Gender.Other
                const timeObject = getMatchTimeStatus(match)
                const otherId = otherUser
                return { ...match, timeObject, name, gender, otherId }
            })
            return enrichedMatches
        },
        enabled: !!session,
    })

    const rateMatch = useMutation({
        mutationKey: ['matches'],
        mutationFn: async (ratingProps: MatchRateObject) => {
            const { matchId, rating } = ratingProps
            if (!matchId) {
                throw new Error('No matchId')
            }
            const body = {
                userId: user?.id,
                rating,
            }
            const response = await fetch(`https://api.namuho.com/matches/${matchId}/rate`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${session?.getAccessToken().getJwtToken()}`,
                },
                body: JSON.stringify(body),
            })
            if (!response.ok) {
                throw new Error('Failed to rate the match')
            }

            return response.json()
        },
        onSuccess: () => {
            queryClient.invalidateQueries(['matches'])
        },
        onError: () => {
            toast.error('Etwas is schief gelaufen')
        },
    })

    const rejectMatch = useMutation({
        mutationKey: ['matches'],
        mutationFn: async (rejectingProps: MatchRejectObject) => {
            const { matchId, timeSlot, comment } = rejectingProps
            if (!matchId) {
                throw new Error('No matchId')
            }
            const body = {
                timeSlot,
                comment,
            }
            const response = await fetch(`https://api.namuho.com/matches/${matchId}/reject`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${session?.getAccessToken().getJwtToken()}`,
                },
                body: JSON.stringify(body),
            })
            if (!response.ok) {
                throw new Error('Failed to rate the match')
            }

            return response.json()
        },
        onSuccess: () => {
            queryClient.invalidateQueries(['matches'])
        },
        onError: () => {
            toast.error('Etwas is schief gelaufen')
        },
    })
    const commentMatch = useMutation({
        mutationKey: ['matches'],
        mutationFn: async (commentProps: MatchCommentObject) => {
            const { matchId, comment } = commentProps
            if (!matchId) {
                throw new Error('No matchId')
            }
            const body = {
                userId: user?.id,
                comment,
            }
            const response = await fetch(`https://api.namuho.com/matches/${matchId}/rate`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${session?.getAccessToken().getJwtToken()}`,
                },
                body: JSON.stringify(body),
            })
            if (!response.ok) {
                throw new Error('Failed to comment on a match')
            }

            return response.json()
        },
        onSuccess: () => {
            queryClient.invalidateQueries(['matches'])
        },
        onError: () => {
            toast.error('Etwas is schief gelaufen')
        },
    })
    return {
        isLoading: matchesData.isLoading,
        matches: matchesData.data || [],
        currentMatches:
            matchesData.data?.filter(
                (match: ConsumableMatch) =>
                    match.timeObject.status === IMatchTimeStatus.Current ||
                    match.timeObject.status === IMatchTimeStatus.PastOpen ||
                    match.timeObject.status === IMatchTimeStatus.PastWaiting
            ) || [],
        futureMatches: matchesData.data?.filter((match: ConsumableMatch) => match.timeObject.status === IMatchTimeStatus.Future) || [],
        pastMatches: matchesData.data?.filter((match: ConsumableMatch) => match.timeObject.status === IMatchTimeStatus.PastClosed) || [],
        rateMatch: rateMatch.mutateAsync,
        rejectMatch: rejectMatch.mutateAsync,
        commentMatch: commentMatch.mutateAsync,
    }
}
