import { makeStyles } from 'tss-react/mui'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import { MeetingType } from '@namuho/types'
import { useFirstDate } from '@/hooks/firstDate/useFirstDate'
import { useSearchParams } from 'react-router-dom'
import { useAuth } from '@/contexts'
import { useQueryClient } from '@tanstack/react-query'

export interface UseFirstDateProps {
    open: boolean
    meetingType: MeetingType
    handleCancel: () => void
    handleClaimed: () => void
}

const useStyles = makeStyles()((theme) => ({
    root: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.primary.main,
    },
    title: {
        color: theme.palette.common.white,
    },
    text: {
        paddingTop: theme.spacing(2),
        color: theme.palette.primary.main,
    },
    actions: {
        backgroundColor: theme.palette.common.white,
    },
}))

export function UseFirstDate(props: UseFirstDateProps) {
    const { meetingType, open, handleCancel, handleClaimed } = props
    const { classes } = useStyles()
    const { consumeFirstDate, isLoading } = useFirstDate()
    const [_, setSearchParams] = useSearchParams()
    const { user } = useAuth()
    const queryClient = useQueryClient()

    const handleClose = () => {
        handleCancel()
    }

    const handleClaim = async () => {
        await consumeFirstDate(meetingType)
        queryClient.invalidateQueries([user?.id])
        setSearchParams({ paymentCallback: 'success' })
        handleClaimed()
    }

    const explanations = {
        [MeetingType.Romantic]: 'Möchtest du deine erstes freies Treffen für ein romantisches Date nutzen?',
        [MeetingType.Friendly]: 'Möchtest du deine erstes freies Treffen für ein freundschaftlich Treffen nutzen?',
        [MeetingType.Group]: 'Möchtest du deine erstes freies Treffen für ein Gruppentreffen nutzen?',
    }

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle className={classes.title}>Erste {meetingType === MeetingType.Romantic ? 'Date' : 'Treffen'} frei freischalten</DialogTitle>
            <DialogContent className={classes.root}>
                <Typography variant="body2" className={classes.text}>
                    {explanations[meetingType]}
                </Typography>
            </DialogContent>
            <DialogActions className={classes.actions}>
                <Button disabled={isLoading} onClick={handleClose}>
                    Abrechen
                </Button>
                <Button disabled={isLoading} onClick={handleClaim}>
                    Ja
                </Button>
            </DialogActions>
        </Dialog>
    )
}
