import { useState } from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from '@/contexts'
import { Checkbox, Input } from '@/components/common'
import { Visibility } from '@mui/icons-material'
import { makeStyles } from 'tss-react/mui'
import { Button } from '@mui/material'

const useStyles = makeStyles()((theme) => ({
    passwordBox: {
        position: 'relative',
    },
    passwordIcon: {
        color: theme.palette.grey[500],
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        right: theme.spacing(2),
        cursor: 'pointer',
    },
}))

export function SignInForm() {
    const { signIn } = useAuth()

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [passwordVisible, setPasswordVisible] = useState(false)
    const [remember, setRemember] = useState(false)
    const { classes } = useStyles()

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        signIn(email, password)
    }

    return (
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
            <Link to="/" className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
                <img src="/logo.svg" alt="Logo" className="w-80" />
            </Link>
            <div className="w-full bg-white shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                    <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                        In dein Konto einloggen
                    </h1>
                    <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
                        <Input
                            type="email"
                            name="email"
                            id="email"
                            autoComplete="email"
                            placeholder="name@email.com"
                            value={email}
                            setValue={setEmail}
                            required
                        />

                        <div className={classes.passwordBox}>
                            <Input
                                type={passwordVisible ? 'text' : 'password'}
                                name="password"
                                id="password"
                                placeholder="••••••••••••"
                                autoComplete="current-password"
                                value={password}
                                setValue={setPassword}
                                required
                            />
                            <Visibility className={classes.passwordIcon} onClick={() => setPasswordVisible(!passwordVisible)} />
                        </div>

                        <div className="flex items-center justify-between">
                            <Checkbox
                                id="remember"
                                name="remember"
                                aria-describedby="remember"
                                label="Eingeloggt bleiben auf diesem Gerät"
                                checked={remember}
                                setChecked={setRemember}
                            />
                            <Link to="/auth/forgot-password" className="text-sm font-medium text-pink-600 hover:underline dark:text-pink-600">
                                Passwort vergessen?
                            </Link>
                        </div>

                        <Button variant="contained" fullWidth type="submit">
                            Einloggen
                        </Button>

                        <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                            Noch kein Konto?{' '}
                            <Link to="/start" className="font-medium text-pink-600 hover:underline dark:text-pink-600">
                                Hier beginnen
                            </Link>
                        </p>
                    </form>
                </div>
            </div>
        </div>
    )
}
