import { createContext, ProviderProps, useCallback, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { CognitoUserPool, CognitoUser, AuthenticationDetails, CognitoUserSession, CognitoUserAttribute } from 'amazon-cognito-identity-js'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import { setSilentSignIn, setUserConfirmed } from '@/slices/user'
import { LoadingPage } from '@/components/common/LoadingPage'
import { IUser } from '@namuho/types'

const userPool = new CognitoUserPool({
    UserPoolId: 'eu-west-1_2r8Nlekpq',
    ClientId: '3i3e5a2g987alp2raqo9b6j44m',
})

export interface IUserAttributes {
    email: string
    email_verified?: string
    given_name: string
    family_name: string
}

export interface UserCreationObject {
    firstname: string
    lastname: string
    email: string
    gender: string
    country: string
    city: string
    birthdate: string
    password: string
}

export interface IAuthContext {
    user: IUser | null
    signIn: (email: string, password: string) => void
    signUp: (user: UserCreationObject) => void
    confirmEmail: (email: string, code: string) => void
    resendCode: (email: string) => void
    changePassword: (oldPassword: string, newPassword: string) => void
    newPasswordRequired: (newPassword: string) => void
    forgotPassword: (email: string) => void
    resetPassword: (email: string, code: string, newPassword: string) => void
    signOut: (withRedirect?: boolean) => void
    globalSignOut: () => void
    loading: boolean
    session: CognitoUserSession | null
    userAttributes: IUserAttributes | null
    cognitoUser: CognitoUser | null
}

export const AuthContext = createContext({
    user: null as IUser | null,
    signIn: (_email: string, _password: string): void => {
        throw new Error('AuthContext.signIn() not implemented')
    },
    signUp: (user: UserCreationObject): void => {
        throw new Error('AuthContext.signUp() not implemented')
    },
    confirmEmail: (_email: string, _code: string): void => {
        throw new Error('AuthContext.confirmEmail() not implemented')
    },
    resendCode: (_email: string): void => {
        throw new Error('AuthContext.resendCode() not implemented')
    },
    changePassword: (_oldPassword: string, _newPassword: string): void => {
        throw new Error('AuthContext.changePassword() not implemented')
    },
    newPasswordRequired: (_password: string): void => {
        throw new Error('AuthContext.newPasswordRequired() not implemented')
    },
    forgotPassword: (_email: string): void => {
        throw new Error('AuthContext.forgotPassword() not implemented')
    },
    resetPassword: (_email: string, _code: string, _newPassword: string): void => {
        throw new Error('AuthContext.resetPassword() not implemented')
    },
    signOut: (_withRedirect?: boolean): void => {
        throw new Error('AuthContext.signOut() not implemented')
    },
    globalSignOut: (): void => {
        throw new Error('AuthContext.globalSignOut() not implemented')
    },
    loading: true,
    session: null as CognitoUserSession | null,
    userAttributes: null as IUserAttributes | null,
    cognitoUser: null as CognitoUser | null,
})

export const AuthProvider = ({ children }: Omit<ProviderProps<IAuthContext>, 'value'>) => {
    const [loading, setLoading] = useState(true)
    const [session, setSession] = useState<CognitoUserSession | null>(null)
    const [user, setUser] = useState<IUser | null>(null)
    const [userAttributes, setUserAttributes] = useState<IUserAttributes | null>(null)
    const [cognitoUser, setCognitoUser] = useState<CognitoUser | null>(null)
    const dispatch = useDispatch()

    const navigate = useNavigate()

    const signOut = useCallback(
        (withRedirect = true) => {
            if (cognitoUser) {
                cognitoUser.signOut()
                toast.success('Erfolgreich abgemeldet')
                setUser(null)
                setSession(null)
                setUserAttributes(null)
                if (withRedirect) navigate('/auth/sign-in')
            } else {
                console.error('No user currently signed in')
            }
        },
        [cognitoUser, navigate]
    )

    const globalSignOut = useCallback(() => {
        if (cognitoUser) {
            cognitoUser.globalSignOut({
                onSuccess: (result) => {
                    toast.success('Erfolgreich abgemeldet global')
                    setUser(null)
                    setSession(null)
                    setUserAttributes(null)
                    navigate('/auth/sign-in')
                },
                onFailure: (err) => {
                    console.log('globalSignOut error:', err)
                },
            })
        } else {
            console.error('No user currently signed in')
        }
    }, [cognitoUser, navigate])

    const getUser = useCallback(() => {
        if (cognitoUser) {
            cognitoUser.getSession((err: Error | null, session: CognitoUserSession) => {
                if (err) {
                    if (err.message.startsWith('Local storage is missing')) return
                    console.log('getSession error:', err)
                    setLoading(false)
                    return
                }

                if (session.isValid()) {
                    setSession(session)

                    cognitoUser.getUserAttributes(async (err, attributes) => {
                        if (err) {
                            console.log('getUserAttributes error:', err)
                            toast.error(err.message)
                            setLoading(false)
                            return
                        }

                        setUserAttributes(
                            attributes?.reduce((acc, attr) => {
                                acc[attr.getName() as keyof IUserAttributes] = attr.getValue()
                                return acc
                            }, {} as IUserAttributes) || null
                        )

                        const res = await fetch(`https://api.namuho.com/users/${cognitoUser.getUsername()}`, {
                            method: 'GET',
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: `${session?.getAccessToken().getJwtToken()}`,
                            },
                        })

                        const data: IUser = await res.json()

                        setUser(data)

                        setLoading(false)
                    })
                }
            })
        } else {
            console.error('No user currently signed in')
        }
    }, [cognitoUser])

    const signIn = useCallback(
        (email: string, password: string) => {
            const cognitoUser = new CognitoUser({
                Username: email,
                Pool: userPool,
            })

            cognitoUser.authenticateUser(
                new AuthenticationDetails({
                    Username: email,
                    Password: password,
                }),
                {
                    onSuccess: async (result) => {
                        cognitoUser.getUserAttributes(async (err, attributes) => {
                            if (err) {
                                console.log('getUserAttributes error:', err)
                                toast.error(err.message)
                                setLoading(false)
                                return
                            }

                            setCognitoUser(cognitoUser)
                            setSession(result)

                            setUserAttributes(
                                attributes?.reduce((acc, attr) => {
                                    acc[attr.getName() as keyof IUserAttributes] = attr.getValue()
                                    return acc
                                }, {} as IUserAttributes) || null
                            )

                            const res = await fetch(`https://api.namuho.com/users/${cognitoUser.getUsername()}`)

                            const data: IUser = await res.json()

                            setUser(data)

                            dispatch(setSilentSignIn('YES'))
                        })
                    },
                    onFailure: (err) => {
                        if (err.code === 'NotAuthorizedException') {
                            dispatch(setSilentSignIn('WRONG_PASSWORD'))
                            toast.error('Falsches Passwort')
                            return
                        }
                        if (err.code === 'UserNotConfirmedException') {
                            navigate(`/auth/confirm-email/${email}`)
                            return
                        }
                        console.log('onFailure:', err)
                        toast.error(err.message)
                        cognitoUser?.signOut()
                        setSession(null)
                        setUser(null)
                        setUserAttributes(null)
                        setCognitoUser(null)
                        navigate('/auth/sign-in')
                    },
                    newPasswordRequired: (userAttributes, requiredAttributes) => {
                        setUserAttributes(userAttributes)
                        setCognitoUser(cognitoUser)
                        navigate('/auth/new-password-required')
                    },
                }
            )
        },
        [dispatch, navigate]
    )

    const signUp = useCallback((user: UserCreationObject) => {
        const { firstname, lastname, email, gender, birthdate, password, city, country } = user
        const attributeList = [
            new CognitoUserAttribute({
                Name: 'email',
                Value: email,
            }),
            new CognitoUserAttribute({
                Name: 'given_name',
                Value: firstname,
            }),
            new CognitoUserAttribute({
                Name: 'family_name',
                Value: lastname,
            }),
            new CognitoUserAttribute({
                Name: 'birthdate',
                Value: birthdate,
            }),
            new CognitoUserAttribute({
                Name: 'gender',
                Value: gender,
            }),
            new CognitoUserAttribute({
                Name: 'custom:city',
                Value: city,
            }),
            new CognitoUserAttribute({
                Name: 'custom:country',
                Value: country,
            }),
        ]

        userPool.signUp(email, password, attributeList, [], (err, result) => {
            if (err) {
                console.log('signUp error:', err)
                toast.error(err.message)
                return
            }

            if (result) {
                setCognitoUser(result.user)
            }
        })
    }, [])

    const confirmEmail = useCallback(
        (email: string, code: string) => {
            const cognitoUser = new CognitoUser({
                Username: email,
                Pool: userPool,
            })

            dispatch(setSilentSignIn('PENDING'))
            cognitoUser.confirmRegistration(code, true, (err, result) => {
                if (err) {
                    console.log('confirmRegistration error:', err)
                    toast.error(err.message)
                    dispatch(setSilentSignIn('OTHER_ERROR'))
                    return
                }
                dispatch(setSilentSignIn('CONFIRMED'))
                dispatch(setUserConfirmed())

                toast.success('Dein E-Mail ist bestätigt, danke')
            })
        },
        [dispatch]
    )

    const resendCode = useCallback((email: string) => {
        const cognitoUser = new CognitoUser({
            Username: email,
            Pool: userPool,
        })

        cognitoUser.resendConfirmationCode((err, result) => {
            if (err) {
                console.log('resendConfirmationCode error:', err)
                toast.error(err.message)
                return
            }

            toast.success('Code erfolgreich gesendet')
        })
    }, [])

    const changePassword = useCallback(() => {
        if (cognitoUser) {
            cognitoUser.changePassword('oldPassword', 'newPassword', (err, result) => {
                if (err) {
                    console.log('changePassword error:', err)
                    toast.error(err.message)
                    return
                }

                toast.success('Passwort erfolgreich geändert')
            })
        } else {
            toast.error('Kein User angemeldet')
        }
    }, [cognitoUser])

    const newPasswordRequired = useCallback(
        (newPassword: string) => {
            if (cognitoUser) {
                cognitoUser.completeNewPasswordChallenge(newPassword, null, {
                    onSuccess: (result) => {
                        toast.success('Passwort erfolgreich geändert')
                        setSession(result)
                        navigate('/dashboard')
                    },
                    onFailure: (err) => {
                        console.log('onFailure:', err)
                        toast.error(err.message)
                        cognitoUser?.signOut()
                        setSession(null)
                        setUser(null)
                        setUserAttributes(null)
                        navigate('/auth/sign-in')
                    },
                })
            } else {
                toast.error('Kein User angemeldet')
            }
        },
        [cognitoUser, navigate]
    )

    const forgotPassword = useCallback(
        (email: string) => {
            const cognitoUser = new CognitoUser({
                Username: email,
                Pool: userPool,
            })

            cognitoUser.forgotPassword({
                onSuccess: (data) => {
                    console.log('forgotPassSuccess', data)
                },
                onFailure: (err) => {
                    console.log('onFailure:', err)
                    toast.error(err.message)
                },
                inputVerificationCode: () => {
                    navigate(`/auth/reset-password/${email}`)
                },
            })
        },
        [navigate]
    )

    const resetPassword = useCallback(
        (email: string, code: string, newPassword: string) => {
            const cognitoUser = new CognitoUser({
                Username: email,
                Pool: userPool,
            })

            cognitoUser.confirmPassword(code, newPassword, {
                onSuccess: () => {
                    toast.success('Passwort erfolgreich geändert')
                    navigate('/auth/sign-in')
                },
                onFailure: (err) => {
                    console.log('onFailure:', err)
                    toast.error(err.message)
                },
            })
        },
        [navigate]
    )

    useEffect(() => {
        const user = userPool.getCurrentUser()
        setCognitoUser(user)
        if (!user) {
            setLoading(false)
        }
    }, [])

    useEffect(() => {
        if (cognitoUser) getUser()
    }, [cognitoUser, getUser])

    if (loading) {
        return <LoadingPage />
    }

    return (
        <AuthContext.Provider
            value={{
                user,
                signIn,
                signUp,
                confirmEmail,
                resendCode,
                changePassword,
                newPasswordRequired,
                forgotPassword,
                resetPassword,
                signOut,
                globalSignOut,
                loading,
                session,
                userAttributes,
                cognitoUser,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export const useAuth = () => {
    return useContext(AuthContext)
}
