import { useAuth } from '@/contexts'
import { MeetingType } from '@namuho/types'
import { useMutation } from '@tanstack/react-query'
import { toast } from 'react-toastify'

export const useFirstDate = () => {
    const baseUrl = 'https://api.namuho.com/users/'

    const { session, user } = useAuth()
    const consumeFirstDate = useMutation({
        mutationKey: [user?.id],
        mutationFn: async (meetingType: MeetingType) => {
            const body = {
                meetingType,
            }
            const response = await fetch(`${baseUrl}${user?.id}/firstDate`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${session?.getAccessToken().getJwtToken()}`,
                },
                body: JSON.stringify(body),
            })
            return response.json()
        },
        onSuccess: (data) => {
            if (data.code === 'OK') {
                toast.success('Erste Date/Treffen frei eingelöst')
            }
        },
        onError: () => {
            toast.error('Etwas is schief gelaufen')
        },
    })

    return {
        consumeFirstDate: consumeFirstDate.mutateAsync,
        isLoading: consumeFirstDate.isLoading,
    }
}
