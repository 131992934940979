import { Footer } from '@/components/nav/Footer'
import { Container, Paper, Typography } from '@mui/material'
import { Link, useRouteError } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'
import { ThemeProvider } from '@mui/material/styles'
import { CssBaseline } from '@mui/material'
import theme from '@/theme/theme'
import * as Sentry from '@sentry/react'

interface IError {
    data: string
    error: Error
    internal: boolean
    status: number
    statusText: string
}

const useStyles = makeStyles()((theme) => ({
    page: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'space-between',
        minHeight: '100vh',
    },
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: theme.spacing(2),
        margin: theme.spacing(4),
        padding: theme.spacing(2),
        backgroundColor: theme.palette.common.white,
    },
}))
export function ErrorPage() {
    const error = useRouteError() as IError
    const { classes } = useStyles()

    Sentry.captureException(error, {
        tags: {
            section: 'global',
        },
        extra: {
            boundary: true,
        },
    })

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <div className={classes.page}>
                <Container component="main">
                    <Paper className={classes.root}>
                        <Typography variant="h1">404 - Seite nicht gefunden</Typography>
                        <Typography variant="h2">Etwas ist schief gelaufen</Typography>
                        <Link className="inline-link" to="/">
                            Zurück zur Dashboard
                        </Link>
                        <Typography variant="caption">
                            Wenn du Fragen hast, kontaktiere uns unter{' '}
                            <a className="inline-link" href="mailto:info@namuho.com">
                                info@namuho.com
                            </a>
                        </Typography>
                    </Paper>
                </Container>
                <Footer />
            </div>
        </ThemeProvider>
    )
}
