import { makeStyles } from 'tss-react/mui'
import { Chip } from '@mui/material'
import { IMatchTimeStatus, MeetingType } from '@namuho/types'
import { ConsumableMatch } from '@/hooks/user/useUserMatches'
import { useMatchSecrets } from '@/hooks/matchSecrets/useMatchSecrets'
import { BuySecrets } from '../BuySecrets'
import { useState } from 'react'

const useStyles = makeStyles()((theme) => ({
    root: {
        marginTop: theme.spacing(1),
        display: 'flex',
        flexWrap: 'wrap',
        gap: theme.spacing(1),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        borderTop: `1px solid ${theme.palette.secondary.main}`,
    },
    new: {
        backgroundColor: theme.palette.primary.main,
        border: `1px solid ${theme.palette.common.white}`,
        color: theme.palette.common.white,
    },
    romantic: {
        backgroundColor: theme.palette.romantic?.main,
        color: theme.palette.romantic?.contrastText,
    },
}))

export interface StatusBarProps {
    date: ConsumableMatch
    size?: 'small' | 'medium'
}

export const StatusBar = (props: StatusBarProps) => {
    const { classes } = useStyles()
    const [secretsOpen, setSecretsOpen] = useState(false)

    const {
        date: { id, meetingType, timeObject },
        size = 'medium',
    } = props
    const { secretsAvailable } = useMatchSecrets(id)

    const handleCancel = () => {
        setSecretsOpen(false)
    }

    const handleSecretsOpen = () => {
        setSecretsOpen(true)
    }

    return (
        <div className={classes.root}>
            {timeObject.status === IMatchTimeStatus.Future && <Chip size={size} className={classes.new} label="Neu" />}
            {meetingType === MeetingType.Romantic && <Chip size={size} color="romantic" label="Romantich" />}
            {meetingType === MeetingType.Friendly && <Chip size={size} color="secondary" label="Freundschaftlich" />}
            {secretsAvailable && <Chip size={size} color="go" label="Geheimnise verfügbar" onClick={handleSecretsOpen} />}
            <BuySecrets match={props.date} open={secretsOpen} handleCancel={handleCancel} />
        </div>
    )
}
