import { useEffect, useState } from 'react'
import { SecretsForm } from '@/types/Questions'
import { Alert, Button, Container, Divider, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { Block } from './Block'
import { TextInput } from './common/TextInput'
import { SecretsSkeleton } from '@/skeletons/SecretsSkeleton'
import { useForm } from 'react-hook-form'
import { IQuestion, ISecretsQuestionAnswer, MeetingType } from '@namuho/types'
import { usePreferences } from '@/hooks/preferences/usePreferences'
import { useQuestions } from '@/hooks/questions/useQuestions'
import { isEmptyAnswer } from '@namuho/helpers'

const useStyles = makeStyles()((theme) => ({
    subHeader: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    paragraph: {
        marginBottom: theme.spacing(2),
    },
    root: {
        width: '100%',
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
        alignItems: 'stretch',
        justifyContent: 'center',
    },
    form: {
        marginTop: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'center',
        gap: theme.spacing(2),
        width: '100%',
    },
    divider: {
        marginBottom: theme.spacing(2),
    },
    buttons: {
        marginLeft: 'auto',
        display: 'flex',
        gap: theme.spacing(1),
    },
    editButton: {
        marginTop: theme.spacing(2),
        alignSelf: 'flex-end',
    },
    alert: {
        marginTop: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
        },
    },
}))

export function Secrets() {
    const { classes } = useStyles()
    const {
        isLoading: isLoadingPreferences,
        friendlyPreferences,
        romanticPreferences,
        updateFriendlyPreferences,
        updateRomanticPreferences,
    } = usePreferences()
    const { secrets, isLoading: isLoadingQuestions } = useQuestions()

    const [isEdited, setIsEdited] = useState(false)
    const [allEmpty, setAllEmpty] = useState(false)
    const [warningDissmissed, setWarningDissmissed] = useState(false)
    const [showWarning, setShowWarning] = useState(false)
    const { register, getValues, setValue } = useForm<SecretsForm>()
    const handleEdit = () => {
        setIsEdited(true)
    }
    const handleWarningDismiss = () => {
        setWarningDissmissed(true)
    }

    const handleCancel = () => {
        setIsEdited(false)
    }

    const handleSave = async () => {
        const values = getValues()
        const friendlyQuestions =
            secrets?.filter((question: IQuestion) => question.meetingType === MeetingType.Friendly).map((question: IQuestion) => question.id) || []
        const romanticQuestions =
            secrets?.filter((question: IQuestion) => question.meetingType === MeetingType.Romantic).map((question: IQuestion) => question.id) || []
        const friendlyAnswers: ISecretsQuestionAnswer[] = Object.keys(values)
            .map((key) => ({
                questionId: key,
                answer: values[key],
            }))
            .filter((answer) => friendlyQuestions.includes(answer.questionId))
        const romanticAnswers: ISecretsQuestionAnswer[] = Object.keys(values)
            .map((key) => ({
                questionId: key,
                answer: values[key],
            }))
            .filter((answer) => romanticQuestions.includes(answer.questionId))
        const answeredFriendlyQuestions = friendlyAnswers.filter((answer) => !isEmptyAnswer(answer.answer))
        const answeredRomanticQuestions = romanticAnswers.filter((answer) => !isEmptyAnswer(answer.answer))
        if (!warningDissmissed && answeredFriendlyQuestions.length < friendlyQuestions.length && answeredFriendlyQuestions.length > 0) {
            setShowWarning(true)
            return null
        }
        if (!warningDissmissed && answeredRomanticQuestions.length < romanticQuestions.length && answeredRomanticQuestions.length > 0) {
            setShowWarning(true)
            return null
        }
        await updateFriendlyPreferences({
            ...friendlyPreferences,
            secretQuestions: friendlyAnswers,
        })
        await updateRomanticPreferences({
            ...romanticPreferences,
            secretQuestions: romanticAnswers,
        })
        setIsEdited(false)
    }

    useEffect(() => {
        try {
            if (friendlyPreferences) {
                const secretsAnswers = friendlyPreferences.secretQuestions
                secretsAnswers?.forEach((answer: ISecretsQuestionAnswer) => {
                    setValue(answer.questionId, answer.answer)
                })
            }
            if (romanticPreferences) {
                const secretsAnswers = romanticPreferences.secretQuestions
                secretsAnswers?.forEach((answer: ISecretsQuestionAnswer) => {
                    setValue(answer.questionId, answer.answer)
                })
            }
            // if all answers empty, set isEdited to true
            const values = getValues()
            const friendlyQuestions =
                secrets?.filter((question: IQuestion) => question.meetingType === MeetingType.Friendly).map((question: IQuestion) => question.id) ||
                []
            const romanticQuestions =
                secrets?.filter((question: IQuestion) => question.meetingType === MeetingType.Romantic).map((question: IQuestion) => question.id) ||
                []
            const friendlyAnswers: ISecretsQuestionAnswer[] = Object.keys(values)
                .map((key) => ({
                    questionId: key,
                    answer: values[key],
                }))
                .filter((answer) => friendlyQuestions.includes(answer.questionId))
            const romanticAnswers: ISecretsQuestionAnswer[] = Object.keys(values)
                .map((key) => ({
                    questionId: key,
                    answer: values[key],
                }))
                .filter((answer) => romanticQuestions.includes(answer.questionId))
            const answeredFriendlyQuestions = friendlyAnswers.filter((answer) => !isEmptyAnswer(answer.answer))
            const answeredRomanticQuestions = romanticAnswers.filter((answer) => !isEmptyAnswer(answer.answer))
            if (answeredFriendlyQuestions.length === 0 && answeredRomanticQuestions.length === 0) {
                setIsEdited(true)
                setAllEmpty(true)
            } else {
                setAllEmpty(false)
            }
        } catch (e) {
            console.log(e)
        }
    }, [friendlyPreferences, getValues, romanticPreferences, secrets, setValue])

    return (
        <Container id="secrets">
            <Block title="Deine Geheimnise">
                <Typography className={classes.subHeader} variant="h2">
                    Verrate uns deine Geheimnisse.
                </Typography>
                <Typography variant="body1">
                    Um Geheimnisse deiner Dates zu erfahren/freischalten, musst du alle <strong>Geheimnisse von dir übermitteln.</strong>{' '}
                    <a className="inline-link" rel="noreferrer" target="_blank" href="https://namuho.com/anleitung/#geheimnisse">
                        Mehr Informationen zu Geheimnissen
                    </a>
                </Typography>
                <div className={classes.root}>
                    {(isLoadingPreferences || isLoadingQuestions) && <SecretsSkeleton />}
                    {!isEdited && (
                        <Button className={classes.editButton} variant="contained" color="secondary" onClick={handleEdit}>
                            Bearbeiten
                        </Button>
                    )}
                    {secrets && secrets.length > 0 && (
                        <form className={classes.form}>
                            <Typography variant="h2">Freundschaftliche Geheimnisse:</Typography>
                            {secrets
                                .filter((question: IQuestion) => question.meetingType === MeetingType.Friendly)
                                .map((secret: IQuestion) => (
                                    <TextInput key={secret.id} label={secret.label} disabled={!isEdited} {...register(secret.id)} />
                                ))}
                            <Divider className={classes.divider} variant="fullWidth" />
                            <Typography variant="h2">Romantische Geheimnisse:</Typography>
                            {secrets
                                .filter((question: IQuestion) => question.meetingType === MeetingType.Romantic)
                                .map((secret: IQuestion) => (
                                    <TextInput key={secret.label} label={secret.label} disabled={!isEdited} {...register(secret.id)} />
                                ))}
                        </form>
                    )}
                    {isEdited && showWarning && !warningDissmissed && (
                        <Alert
                            className={classes.alert}
                            severity="warning"
                            action={
                                <Button color="inherit" size="small" onClick={handleWarningDismiss}>
                                    OK
                                </Button>
                            }
                        >
                            Du hast nur einige geheime Fragen beantwortet. Um Zugang zu den Geheimnissen der Personen zu erhalten, mit denen du dich
                            treffen wirst, musst du alle Fragen beantworten.
                        </Alert>
                    )}
                    {!isEdited && (
                        <Button className={classes.editButton} variant="contained" color="secondary" onClick={handleEdit}>
                            Bearbeiten
                        </Button>
                    )}
                    {isEdited && (
                        <div className={classes.buttons}>
                            {!allEmpty && (
                                <Button className={classes.editButton} variant="contained" color="secondary" onClick={handleCancel}>
                                    Stornieren
                                </Button>
                            )}
                            <Button className={classes.editButton} variant="contained" color="primary" onClick={handleSave}>
                                Speichern
                            </Button>
                        </div>
                    )}
                </div>
            </Block>
        </Container>
    )
}
