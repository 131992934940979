import clsx from 'clsx'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Container,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useUser } from '@/hooks/user/useUser'
import { useUserTransactions } from '@/hooks/user/useUserTransactions'
import { makeStyles } from 'tss-react/mui'
import { ITransaction, MeetingType, ProductType } from '@namuho/types'
import { usePreferences } from '@/hooks/preferences/usePreferences'
import { formatDateDaySimple } from '@namuho/helpers'

const useStyles = makeStyles()((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
    },
    button: {
        alignSelf: 'flex-end',
    },
    loading: {
        opacity: '50%',
    },
    topMeta: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        gap: theme.spacing(2),
        padding: theme.spacing(1),
    },
}))

export const CreditsHistory = () => {
    const { userData } = useUser()
    const { classes } = useStyles()
    const { transactions, isLoading } = useUserTransactions()
    const { friendlyPreferences, romanticPreferences } = usePreferences()

    const getTransactionDetails = (transaction: ITransaction) => {
        if (transaction.productType === ProductType.AllSecrets) {
            return 'Alle Geheimnisse'
        }
        if (transaction.productType === ProductType.SingleSecret) {
            return 'Einzelne(s) Geheimnis(e)'
        }
        if (transaction.productType === ProductType.Date) {
            return `${transaction.dates} ${transaction.extraDates ? `+ ${transaction.extraDates}` : ''} ${
                transaction.meetingType === MeetingType.Romantic ? 'Dates' : 'Treffen'
            }`
        }
    }

    const hasFriendlyCredits = friendlyPreferences && friendlyPreferences.numberOfAvailableSlots > 0
    const hasRomanticCredits = romanticPreferences && romanticPreferences.numberOfAvailableSlots > 0

    return (
        <Container id="creditHistory">
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="creditsHistoryContent" id="creditsHistory-header">
                    <Typography variant="h1">Credits Historie</Typography>
                </AccordionSummary>
                <AccordionDetails id="creditsHistoryContent">
                    <div className={clsx(classes.root)}>
                        <div className={classes.topMeta}>
                            {userData?.totalNumberOfDatesBought && (
                                <>
                                    <Typography variant="h2">({userData.totalNumberOfDatesBought} insgesamt)</Typography>
                                    <Typography variant="caption">Jede 10 Verabredung, erhälst du 1 gratis Verabredung</Typography>
                                </>
                            )}
                        </div>
                        {(hasFriendlyCredits || hasRomanticCredits) && (
                            <div className={classes.topMeta}>
                                <Typography variant="h2">Deine übrigen Credits:</Typography>
                                {hasFriendlyCredits && (
                                    <Typography variant="caption">
                                        für freundschaftliche Treffen: <strong>{friendlyPreferences.numberOfAvailableSlots}</strong>
                                        {userData?.totalNumberOfFriendlyDatesBought && (
                                            <span> von&nbsp;{userData.totalNumberOfFriendlyDatesBought} insgesamt</span>
                                        )}
                                    </Typography>
                                )}
                                {hasRomanticCredits && (
                                    <Typography variant="caption">
                                        für romantische Dates: <strong>{romanticPreferences.numberOfAvailableSlots}</strong>
                                        {userData?.totalNumberOfRomanticDatesBought && (
                                            <span> von&nbsp;{userData.totalNumberOfRomanticDatesBought} insgesamt</span>
                                        )}
                                    </Typography>
                                )}
                            </div>
                        )}
                        <TableContainer component={Box}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Datum</TableCell>
                                        <TableCell>Paket</TableCell>
                                        <TableCell>Details</TableCell>
                                        <TableCell>Preis</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!isLoading &&
                                        transactions.map((transaction: ITransaction) => (
                                            <TableRow key={transaction.id}>
                                                <TableCell>{formatDateDaySimple(transaction._metadata.createdAt)}</TableCell>
                                                <TableCell>{transaction.productName}</TableCell>
                                                <TableCell>{getTransactionDetails(transaction)}</TableCell>
                                                <TableCell>
                                                    {(transaction.price / 100).toLocaleString('de-DE', {
                                                        style: 'currency',
                                                        currency: 'EUR',
                                                    })}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </AccordionDetails>
            </Accordion>
        </Container>
    )
}
