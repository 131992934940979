import { useUser } from './useUser'
import { IMatch, IQuestion, ISecretsQuestionAnswer, ISlot, MeetingType } from '@namuho/types'
import { isEmptyAnswer } from '@namuho/helpers'
import { useQuestions } from '../questions/useQuestions'
import { useUserMatches } from './useUserMatches'
import { useSlots } from '../slots/useSlots'

export const useReadiness = () => {
    const { isLoading, userData } = useUser()
    const { isLoading: isLoadingQuestions, secrets, weighted } = useQuestions()
    const { isLoading: isLoadingMatches, matches } = useUserMatches()
    const {
        personalDetails,
        [MeetingType.Friendly]: friendlyMeeting,
        [MeetingType.Romantic]: romanticMeeting,
        [MeetingType.Group]: groupMeeting,
        timeSlots,
    } = userData || {}
    const { slots } = useSlots()

    const personalDetailsQuestions: string[] = ['smokingConsumption', 'bodyType', 'height', 'alcoholConsumption', 'kids', 'pets']

    let personalDetailsAnswered = false
    if (personalDetails) {
        const answeredPersonalDetails = Object.keys(personalDetails).filter((key) => !isEmptyAnswer(personalDetails[key]))
        personalDetailsAnswered = answeredPersonalDetails.length === personalDetailsQuestions.length
    }

    const nonEmptyRomanticSecretsAnswers =
        romanticMeeting?.secretQuestions?.filter((answer: ISecretsQuestionAnswer) => !isEmptyAnswer(answer.answer)) || []
    const romanticSecretsAnswered =
        nonEmptyRomanticSecretsAnswers.length === secrets.filter((secret: IQuestion) => secret.meetingType === MeetingType.Romantic).length &&
        nonEmptyRomanticSecretsAnswers.length > 0

    const nonEmptyFriendlySecretsAnswers =
        friendlyMeeting?.secretQuestions?.filter((answer: ISecretsQuestionAnswer) => !isEmptyAnswer(answer.answer)) || []
    const friendlySecretsAnswered =
        nonEmptyFriendlySecretsAnswers.length === secrets.filter((secret: IQuestion) => secret.meetingType === MeetingType.Friendly).length &&
        nonEmptyFriendlySecretsAnswers.length > 0

    const romanticWeightedAnswered =
        romanticMeeting?.weightedQuestions?.length === weighted.filter((weighted: IQuestion) => weighted.meetingType === MeetingType.Romantic).length
    const friendlyWeightedAnswered =
        friendlyMeeting?.weightedQuestions?.length === weighted.filter((weighted: IQuestion) => weighted.meetingType === MeetingType.Friendly).length
    const groupWeightedAnswered =
        groupMeeting?.weightedQuestions?.length === weighted.filter((weighted: IQuestion) => weighted.meetingType === MeetingType.Group).length

    // timeSltos that match current slots
    const timeSlotsMatchingCurrent = timeSlots?.filter((slot: ISlot) => slots.find((s: ISlot) => s.id === slot.id)) || []
    const timeSlotsAvailable = timeSlotsMatchingCurrent.length > 2

    const romanticSlotsBought = romanticMeeting?.numberOfAvailableSlots > 0
    const friendSlotsBought = friendlyMeeting?.numberOfAvailableSlots > 0
    const groupSlotsBought = groupMeeting?.numberOfAvailableSlots > 0

    const romanticBasicAnswered = romanticMeeting?.prefferedGender !== undefined && romanticMeeting?.prefferedAge?.max !== undefined
    const friendlyBasicAnswered = friendlyMeeting?.prefferedGender !== undefined && friendlyMeeting?.prefferedAge?.max !== undefined
    const groupBasicAnswered = groupMeeting?.prefferedGender !== undefined && groupMeeting?.prefferedAge?.max !== undefined

    const romanticWaiting = timeSlotsAvailable && romanticBasicAnswered && romanticSlotsBought && romanticWeightedAnswered && personalDetailsAnswered
    const friendlyWaiting = timeSlotsAvailable && friendlyBasicAnswered && friendSlotsBought && friendlyWeightedAnswered
    const groupWaiting = timeSlotsAvailable && groupBasicAnswered && groupWeightedAnswered && groupSlotsBought

    const dictionary = {
        [MeetingType.Romantic]: 'romantic',
        [MeetingType.Friendly]: 'friendly',
        [MeetingType.Group]: 'group',
    }

    return {
        isLoading: isLoading || isLoadingQuestions || isLoadingMatches,
        [MeetingType.Romantic]: {
            basicAnswered: romanticBasicAnswered,
            secretsAnswered: romanticSecretsAnswered,
            weightedAnswered: romanticWeightedAnswered,
            timeSlotsAvailable,
            slotsBought: romanticSlotsBought,
            noGosSeen: romanticMeeting?.noGosSeen,
            waiting: romanticWaiting,
            datesMatched: matches.filter((match: IMatch) => match.meetingType === MeetingType.Romantic).length > 0,
        },

        [MeetingType.Friendly]: {
            basicAnswered: friendlyBasicAnswered,
            secretsAnswered: friendlySecretsAnswered,
            weightedAnswered: friendlyWeightedAnswered,
            timeSlotsAvailable,
            noGosSeen: true,
            slotsBought: friendSlotsBought,
            waiting: friendlyWaiting,
            datesMatched: matches.filter((match: IMatch) => match.meetingType === MeetingType.Friendly).length > 0,
        },
        [MeetingType.Group]: {
            basicAnswered: groupBasicAnswered,
            weightedAnswered: groupWeightedAnswered,
            secretsAnswered: true,
            noGosSeen: true,
            timeSlotsAvailable,
            slotsBought: groupSlotsBought,
            waiting: groupWaiting,
            datesMatched: matches.filter((match: IMatch) => match.meetingType === MeetingType.Group).length > 0,
        },
        personalDetailsAnswered,
        dictionary,
    }
}
