import { useAuth } from '@/contexts'
import { IPersonalDetails, IPreferences, ISlot, MeetingType } from '@namuho/types'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'

export const usePreferences = () => {
    const baseUrl = 'https://api.namuho.com/users/'
    const queryClient = useQueryClient()

    const { session, user } = useAuth()
    const userData = useQuery({
        queryKey: [user?.id],
        queryFn: async () => {
            const response = await fetch(`${baseUrl}${user?.id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${session?.getAccessToken().getJwtToken()}`,
                },
            })

            if (!response.ok) {
                throw new Error(response.statusText)
            }

            return response.json()
        },
        enabled: !!user?.id,
    })
    const updateFriendlyPreferences = useMutation({
        mutationKey: [user?.id],
        mutationFn: async (friendlyPreferences: IPreferences) => {
            const updateObject = {
                [MeetingType.Friendly]: friendlyPreferences,
            }
            const response = await fetch(`${baseUrl}${user?.id}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${session?.getAccessToken().getJwtToken()}`,
                },
                body: JSON.stringify(updateObject),
            })

            if (!response.ok) {
                throw new Error('Failed to update User')
            }

            return response.json()
        },
        onSuccess: (data) => {
            queryClient.setQueryData([user?.id], data)
        },
        onError: () => {
            toast.error('Etwas is schief gelaufen')
        },
    })

    const updateRomanticPreferences = useMutation({
        mutationKey: [user?.id],
        mutationFn: async (romanticPreferences: IPreferences) => {
            const updateObject = {
                [MeetingType.Romantic]: romanticPreferences,
            }
            const response = await fetch(`${baseUrl}${user?.id}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${session?.getAccessToken().getJwtToken()}`,
                },
                body: JSON.stringify(updateObject),
            })

            if (!response.ok) {
                throw new Error('Failed to update User')
            }

            return response.json()
        },
        onSuccess: (data) => {
            queryClient.setQueryData([user?.id], data)
        },
        onError: () => {
            toast.error('Etwas is schief gelaufen')
        },
    })
    const updateGroupPreferenes = useMutation({
        mutationKey: [user?.id],
        mutationFn: async (groupPreferences: IPreferences) => {
            const updateObject = {
                [MeetingType.Group]: groupPreferences,
            }
            const response = await fetch(`${baseUrl}${user?.id}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${session?.getAccessToken().getJwtToken()}`,
                },
                body: JSON.stringify(updateObject),
            })

            if (!response.ok) {
                throw new Error('Failed to update User')
            }

            return response.json()
        },
        onSuccess: (data) => {
            queryClient.setQueryData([user?.id], data)
        },
        onError: () => {
            toast.error('Etwas is schief gelaufen')
        },
    })
    const updatePersonalDetails = useMutation({
        mutationKey: [user?.id],
        mutationFn: async (preferences: IPersonalDetails) => {
            const updateObject = {
                personalDetails: preferences,
            }
            const response = await fetch(`${baseUrl}${user?.id}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${session?.getAccessToken().getJwtToken()}`,
                },
                body: JSON.stringify(updateObject),
            })

            if (!response.ok) {
                throw new Error('Failed to update User')
            }

            return response.json()
        },
        onSuccess: (data) => {
            queryClient.setQueryData([user?.id], data)
        },
        onError: () => {
            toast.error('Etwas is schief gelaufen')
        },
    })
    const updateTimeSlots = useMutation({
        mutationKey: [user?.id],
        mutationFn: async (timeSlots: ISlot[]) => {
            const updateObject = {
                timeSlots,
            }
            const response = await fetch(`${baseUrl}${user?.id}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${session?.getAccessToken().getJwtToken()}`,
                },
                body: JSON.stringify(updateObject),
            })

            if (!response.ok) {
                throw new Error('Failed to update User Time slots')
            }

            return response.json()
        },
        onSuccess: (data) => {
            queryClient.setQueryData([user?.id], data)
        },
        onError: () => {
            toast.error('Etwas is schief gelaufen')
        },
    })

    return {
        isLoading: userData.isLoading,
        personalDetails: userData.data?.personalDetails,
        friendlyPreferences: userData.data?.[MeetingType.Friendly],
        romanticPreferences: userData.data?.[MeetingType.Romantic],
        groupPreferences: userData.data?.[MeetingType.Group],
        updateFriendlyPreferences: updateFriendlyPreferences.mutateAsync,
        updateRomanticPreferences: updateRomanticPreferences.mutateAsync,
        updateGroupPreferences: updateGroupPreferenes.mutateAsync,
        updatePersonalDetails: updatePersonalDetails.mutateAsync,
        updateTimeSlots: updateTimeSlots.mutateAsync,
        updateTimeSlotsLoading: updateTimeSlots.isLoading,
    }
}
