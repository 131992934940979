import { useEffect, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { useForm, Controller } from 'react-hook-form'
import { StepContent } from './StepContent'
import { useDispatch, useSelector } from 'react-redux'
import { Alert, Button, Slider, Typography } from '@mui/material'
import { RatedForm } from '@/types/Questions'
import { CreatorButtons } from './CreatorButtons'
import clsx from 'clsx'
import { RootState } from '@/store'
import { CreatorSteps, markStepCompletedByName, nextStep } from '@/slices/creator'
import { useQuestions } from '@/hooks/questions/useQuestions'
import { IQuestion, IWeightedQuestionAnswer, MeetingType, WeightedAnswer } from '@namuho/types'
import { usePreferences } from '@/hooks/preferences/usePreferences'
import { ratedMarks } from '@/helpers/ratedMarks'
import { Check, QuestionMark } from '@mui/icons-material'
import { StepTitle } from './StepTitle'

const useStyles = makeStyles()((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'center',
        fontSize: '1rem',
    },
    legend: {
        fontSize: '1rem',
        padding: theme.spacing(1),
        [theme.breakpoints.down('md')]: {
            textAlign: 'left',
        },
    },
    answer: {
        width: '100%',
        display: 'grid',
        gridTemplateColumns: '2fr 3fr min-content',
        alignItems: 'center',
        padding: theme.spacing(1),
        gap: theme.spacing(2),
        transition: 'max-height 0.3s ease-in-out',
        overflow: 'hidden',
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            flexDirection: 'column',
        },
    },
    checkIcon: {
        height: '1.5rem',
        marginBottom: theme.spacing(2),
    },
    label: {
        display: 'block',
        width: '100%',
        textAlign: 'start',
    },
    slider: {
        [theme.breakpoints.down('md')]: {
            maxWidth: '90%',
        },
    },
    markLabel: {
        fontSize: '0.9rem',
        color: 'silver',
    },
    markLabelActive: {
        color: theme.palette.primary.main,
    },
    alert: {
        marginTop: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
        },
    },
}))

export const RatedQuestions = () => {
    const { setValue, getValues, control } = useForm<RatedForm>({})
    const { classes } = useStyles()
    const dispatch = useDispatch()
    const chosenDateType = useSelector((state: RootState) => state.creator.chosenDateType)
    const { weighted, isLoading: isLoadingQuestions } = useQuestions()
    const [answeredQuestions, setAnsweredQuestions] = useState<string[]>([])
    const [numbersOfUnasnweredQuestions, setNumbersOfAnansweredQuestions] = useState<number[]>([])
    const [warningDissmissed, setWarningDissmissed] = useState(false)
    const [showWarning, setShowWarning] = useState(false)

    const {
        isLoading: isLoadingPreferences,
        friendlyPreferences,
        romanticPreferences,
        groupPreferences,
        updateGroupPreferences,
        updateFriendlyPreferences,
        updateRomanticPreferences,
    } = usePreferences()

    const currentSetOfWeighted: IQuestion[] = weighted.filter((question: IQuestion) => question.meetingType === chosenDateType)

    const handleAnsweredQuestion = (questionId: string) => {
        setShowWarning(false)
        setNumbersOfAnansweredQuestions([])
        setAnsweredQuestions((prev) => [...new Set([...prev, questionId])])
    }

    const handleWarningDismiss = () => {
        setWarningDissmissed(true)
    }

    const handleNext = async () => {
        const values = getValues()
        if (answeredQuestions.length < currentSetOfWeighted.length && !warningDissmissed) {
            setShowWarning(true)
            // set numbersOfUnasnweredQuestions
            currentSetOfWeighted.forEach((question, index) => {
                if (!answeredQuestions.includes(question.id)) {
                    setNumbersOfAnansweredQuestions((prev) => [...prev, index + 1])
                }
            })

            return null
        }
        const answers: IWeightedQuestionAnswer[] = Object.keys(values).map((key) => ({
            questionId: key,
            answer: values[key] as WeightedAnswer,
        }))
        if (chosenDateType === MeetingType.Friendly) {
            await updateFriendlyPreferences({
                ...friendlyPreferences,
                weightedQuestions: answers,
            })
        }
        if (chosenDateType === MeetingType.Romantic) {
            await updateRomanticPreferences({
                ...romanticPreferences,
                weightedQuestions: answers,
            })
        }
        if (chosenDateType === MeetingType.Group) {
            await updateGroupPreferences({
                ...groupPreferences,
                weightedQuestions: answers,
            })
        }
        dispatch(markStepCompletedByName(CreatorSteps.RATED_QUESTIONS))
        dispatch(nextStep())
        return null
    }

    useEffect(() => {
        try {
            if (chosenDateType === MeetingType.Friendly && friendlyPreferences) {
                const answers = friendlyPreferences.weightedQuestions
                answers?.forEach((answer: IWeightedQuestionAnswer) => {
                    setAnsweredQuestions((prev) => [...new Set([...prev, answer.questionId])])
                    setValue(answer.questionId, answer.answer)
                })
            }
            if (chosenDateType === MeetingType.Romantic && romanticPreferences) {
                const answers = romanticPreferences.weightedQuestions
                answers?.forEach((answer: IWeightedQuestionAnswer) => {
                    setAnsweredQuestions((prev) => [...new Set([...prev, answer.questionId])])
                    setValue(answer.questionId, answer.answer)
                })
            }
            if (chosenDateType === MeetingType.Group && groupPreferences) {
                const answers = groupPreferences.weightedQuestions
                answers?.forEach((answer: IWeightedQuestionAnswer) => {
                    setAnsweredQuestions((prev) => [...new Set([...prev, answer.questionId])])
                    setValue(answer.questionId, answer.answer)
                })
            }
        } catch (e) {
            console.log(e)
        }
    }, [chosenDateType, friendlyPreferences, groupPreferences, romanticPreferences, setValue])

    return (
        <StepContent
            title={<StepTitle title="Wie sehr treffen folgende Sätze auf dich zu?" />}
            content={
                <>
                    <Typography className={classes.legend} variant="body1">
                        1 = Stimmt überhaupt nicht, 5 = Stimmt vollkommen
                    </Typography>

                    <form className={classes.root}>
                        {currentSetOfWeighted.map((question, index) => (
                            <label
                                key={question.id}
                                htmlFor={question.id}
                                onClick={() => handleAnsweredQuestion(question.id)}
                                className={clsx(classes.answer)}
                            >
                                <span className={classes.label}>{`${index + 1}/${currentSetOfWeighted.length} ${question.label}`}</span>
                                <Controller
                                    name={question.id}
                                    control={control}
                                    render={(renderProps) => (
                                        <Slider
                                            classes={{ root: classes.slider, markLabel: classes.markLabel, markLabelActive: classes.markLabelActive }}
                                            {...renderProps}
                                            onChange={(_, value) => {
                                                handleAnsweredQuestion(question.id)
                                                renderProps.field.onChange(value)
                                            }}
                                            value={renderProps.field.value || 3}
                                            marks={ratedMarks}
                                            max={5}
                                            min={1}
                                            step={1}
                                        />
                                    )}
                                />
                                {answeredQuestions.includes(question.id) ? (
                                    <Check color="success" className={classes.checkIcon} />
                                ) : (
                                    <QuestionMark color="primary" className={classes.checkIcon} />
                                )}
                            </label>
                        ))}
                    </form>
                    {showWarning && !warningDissmissed && (
                        <Alert
                            className={classes.alert}
                            severity="warning"
                            action={
                                <Button color="inherit" size="small" onClick={handleWarningDismiss}>
                                    Ja, ich bin mir sicher
                                </Button>
                            }
                        >
                            Du hast einige der Antworten nicht berührt. Bist du sicher, dass du sie mit der Standardantwort belassen will? Nicht
                            berührte Frage(n): {numbersOfUnasnweredQuestions.join(', ')}
                        </Alert>
                    )}
                </>
            }
            buttons={
                <CreatorButtons
                    nextStepActive={true}
                    previousStepActive={true}
                    isFirst={false}
                    isLast={false}
                    skipActive={true}
                    handleNext={handleNext}
                />
            }
        />
    )
}
