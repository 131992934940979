import { useAuth } from '@/contexts'
import { getUnixTime, parseISO } from 'date-fns'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'

export const useChat = (matchId: string | undefined) => {
    const baseUrl = `https://api.namuho.com/matches/${matchId}/messages`
    const queryClient = useQueryClient()

    const { session, user } = useAuth()
    const messages = useQuery([`chat-${matchId}`], async () => {
        if (!matchId) return Promise.resolve([])
        const response = await fetch(baseUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${session?.getAccessToken().getJwtToken()}`,
            },
        })

        if (!response.ok) {
            throw new Error(response.statusText)
        }

        return response.json()
    })

    const sendMessage = useMutation({
        mutationKey: [`chat-${matchId}`],
        mutationFn: async (message: string) => {
            if (!matchId) {
                throw new Error('Failed to send message')
            }
            const body = {
                content: message,
                userId: user?.id,
            }
            const response = await fetch(baseUrl, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    Authorization: `${session?.getAccessToken().getJwtToken()}`,
                },
                body: JSON.stringify(body),
            })
            if (!response.ok) {
                throw new Error('Failed to send message')
            }

            return response.json()
        },
        onSuccess: () => {
            queryClient.invalidateQueries([`chat-${matchId}`])
        },
        onError: (err) => {
            console.log(err)
            toast.error('Etwas is schief gelaufen')
        },
    })

    return {
        isLoading: messages.isLoading,
        messages: messages.data?.sort(
            (a: any, b: any) => getUnixTime(parseISO(a._metadata.createdAt)) - getUnixTime(parseISO(b._metadata.createdAt))
        ),
        sendMessage: sendMessage.mutateAsync,
    }
}
