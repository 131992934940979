import { useAuth } from '@/contexts'
import { useQuery } from '@tanstack/react-query'

export const useUserTransactions = () => {
    const baseUrl = 'https://api.namuho.com/users/'

    const { session, user } = useAuth()
    const transactions = useQuery({
        queryKey: ['transactions', user?.id],
        queryFn: async () => {
            const response = await fetch(`${baseUrl}${user?.id}/transactions`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${session?.getAccessToken().getJwtToken()}`,
                },
            })

            if (!response.ok) {
                throw new Error(response.statusText)
            }
            return response.json()
        },
        enabled: !!session,
    })

    return {
        isLoading: transactions.isLoading,
        transactions: transactions.data || [],
    }
}
