import { ChangeEvent, InputHTMLAttributes, useCallback } from 'react'

export interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
    label?: string
    checked: boolean
    setChecked: (checked: boolean) => void
}

export function Checkbox({ label, checked, setChecked, id, ...restProps }: CheckboxProps) {
    const handleChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            setChecked(event.target.checked)
        },
        [setChecked]
    )

    return (
        <div className="flex items-center">
            <div className="flex items-center h-5">
                <input
                    id={id}
                    type="checkbox"
                    className="w-4 h-4 border border-gray-300 bg-gray-50 focus:ring-3 focus:ring-pink-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-pink-600 dark:ring-offset-gray-800"
                    checked={checked}
                    onChange={handleChange}
                    {...restProps}
                />
            </div>
            {label && (
                <div className="ml-3 text-sm">
                    <label htmlFor={id} className="font-medium text-gray-900 dark:text-white">
                        {label}
                    </label>
                </div>
            )}
        </div>
    )
}
