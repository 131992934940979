import { useEffect, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { Alert, Button, Typography } from '@mui/material'
import { isEmptyAnswer } from '@namuho/helpers'
import { TextInput } from '../common/TextInput'
import { StepContent } from '../stepper/StepContent'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { SecretsForm } from '@/types/Questions'
import { CreatorButtonsGo } from './CreatorButtonsGo'
import { CreatorGoSteps, markStepCompleted, nextStep } from '@/slices/creatorGo'
import { CreatorGoProps } from './CreatorGo'
import { useAuth } from '@/contexts'
import { usePreferences } from '@/hooks/preferences/usePreferences'
import { useQuestions } from '@/hooks/questions/useQuestions'
import { IQuestion, ISecretsQuestionAnswer, MeetingType } from '@namuho/types'
import { SecretsSkeleton } from '@/skeletons/SecretsSkeleton'
import { StepTitle } from '../stepper/StepTitle'

const useStyles = makeStyles()((theme) => ({
    form: {
        marginTop: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: theme.spacing(2),
        width: '100%',
    },
    alert: {
        marginTop: theme.spacing(2),
    },
}))

export interface Secret {
    questionId: string
    questionLabel: string
    questionInternalName: string
}

export interface SecretsAnswers {
    string: string
}

export const SecretsGo = (props: CreatorGoProps) => {
    const { dateType } = props
    const { register, setValue, getValues } = useForm<SecretsForm>()
    const { classes } = useStyles()
    const dispatch = useDispatch()
    const { user } = useAuth()
    const [warningDissmissed, setWarningDissmissed] = useState(false)
    const [showWarning, setShowWarning] = useState(false)
    const [currentSetOfSecrets, setCurrentSetOfSecrets] = useState<any[]>([])
    const {
        isLoading: isLoadingPreferences,
        friendlyPreferences,
        romanticPreferences,
        updateFriendlyPreferences,
        updateRomanticPreferences,
    } = usePreferences()
    const { secrets, isLoading: isLoadingQuestions } = useQuestions()

    const [loading, setIsLoading] = useState(false)

    const handleWarningDismiss = () => {
        setWarningDissmissed(true)
    }

    useEffect(() => {
        if (secrets && secrets.length > 0) {
            const currentSecrets = secrets.filter((question: IQuestion) => question.meetingType === dateType)
            setCurrentSetOfSecrets(currentSecrets)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateType])

    const handleNext = async () => {
        setIsLoading(true)
        const values = getValues()
        const answeredQuestions = Object.keys(getValues()).filter((key: string) => !isEmptyAnswer(values[key]))
        if (!warningDissmissed && answeredQuestions.length < currentSetOfSecrets.length && answeredQuestions.length > 0) {
            setShowWarning(true)
            setIsLoading(false)
            return null
        }
        const friendlyQuestions =
            secrets?.filter((question: IQuestion) => question.meetingType === MeetingType.Friendly).map((question: IQuestion) => question.id) || []
        const romanticQuestions =
            secrets?.filter((question: IQuestion) => question.meetingType === MeetingType.Romantic).map((question: IQuestion) => question.id) || []
        const friendlyAnswers: ISecretsQuestionAnswer[] = Object.keys(values)
            .map((key) => ({
                questionId: key,
                answer: values[key],
            }))
            .filter((answer) => friendlyQuestions.includes(answer.questionId))
        const romanticAnswers: ISecretsQuestionAnswer[] = Object.keys(values)
            .map((key) => ({
                questionId: key,
                answer: values[key],
            }))
            .filter((answer) => romanticQuestions.includes(answer.questionId))
        await updateFriendlyPreferences({
            userId: user?.id || '',
            ...friendlyPreferences,
            secretQuestions: friendlyAnswers,
        })
        await updateRomanticPreferences({
            userId: user?.id || '',
            ...romanticPreferences,
            secretQuestions: romanticAnswers,
        })
        if (answeredQuestions.length === currentSetOfSecrets.length) {
            dispatch(markStepCompleted({ dateType: dateType, step: CreatorGoSteps.SECRETS }))
        }
        setIsLoading(false)
        dispatch(nextStep(dateType))
    }

    useEffect(() => {
        try {
            if (friendlyPreferences) {
                const secretsAnswers = friendlyPreferences.secretQuestions
                secretsAnswers?.forEach((answer: ISecretsQuestionAnswer) => {
                    setValue(answer.questionId, answer.answer)
                })
            }
            if (romanticPreferences) {
                const secretsAnswers = romanticPreferences.secretQuestions
                secretsAnswers?.forEach((answer: ISecretsQuestionAnswer) => {
                    setValue(answer.questionId, answer.answer)
                })
            }
        } catch (e) {
            console.log(e)
        }
    }, [friendlyPreferences, romanticPreferences, setValue])

    return (
        <StepContent
            title={<StepTitle title="Verrate uns deine Geheimnisse." />}
            content={
                <>
                    <Typography variant="body1">
                        Um Geheimnisse deiner Dates zu erfahren/freischalten musst du <strong>Geheimnisse von dir übermitteln.</strong>{' '}
                        <a className="inline-link" rel="noreferrer" target="_blank" href="https://namuho.com/anleitung/#geheimnisse">
                            Mehr Informationen zu Geheimnissen
                        </a>
                    </Typography>
                    {(isLoadingPreferences || isLoadingQuestions) && <SecretsSkeleton />}
                    {currentSetOfSecrets && currentSetOfSecrets.length > 0 && (
                        <form className={classes.form}>
                            {currentSetOfSecrets.map((secret) => (
                                <TextInput key={secret.id} label={secret.label} {...register(secret.id)} />
                            ))}
                        </form>
                    )}
                    {showWarning && !warningDissmissed && (
                        <Alert
                            className={classes.alert}
                            severity="warning"
                            action={
                                <Button color="inherit" size="small" onClick={handleWarningDismiss}>
                                    OK
                                </Button>
                            }
                        >
                            Du hast nur einige geheime Fragen beantwortet. Um Zugang zu den Geheimnissen der Personen zu erhalten, mit denen du dich
                            treffen wirst, musst du alle Fragen beantworten.
                        </Alert>
                    )}
                </>
            }
            buttons={
                <CreatorButtonsGo
                    dateType={dateType}
                    nextStepActive={!loading}
                    previousStepActive={true}
                    isFirst={false}
                    isLast={false}
                    handleNext={handleNext}
                />
            }
        />
    )
}
