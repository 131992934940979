/* eslint-disable react/jsx-no-undef */
import React, { useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { Alert, AppBar, Button, Container, Dialog, IconButton, Paper, Slide, Toolbar, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { TransitionProps } from '@mui/material/transitions'
import { TextInput } from './common/TextInput'
import { IDiscountCode, IProductV2, MeetingType } from '@namuho/types'
import { useAuth } from '@/contexts'
import { Trans } from 'react-i18next'
import { PayPalButtons } from '@paypal/react-paypal-js'
import { useCodes } from '@/hooks/codes/useCodes'
import { useQueryClient } from '@tanstack/react-query'

export interface BuyProductProps {
    product: IProductV2 | null
    open: boolean
    handleCancel: () => void
    handleBought: (numberOfDates: number) => void
    handleProductCoupon: () => void
    dateType: MeetingType
}

const useStyles = makeStyles()((theme) => ({
    root: {},
    container: {
        padding: theme.spacing(2),
    },
    paper: {
        minHeight: 800,
        backgroundColor: theme.palette.common.white,
        padding: theme.spacing(4),
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
    },
    discount: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        gap: theme.spacing(2),
        alignItems: 'end',
    },
    priceBeforeDiscount: {
        fontSize: '1.1rem',
        color: '#16293A',
        textDecoration: 'line-through',
    },
    priceWithCoupon: {
        display: 'flex',
    },
    forDatesLink: {
        color: theme.palette.primary.main,
        textDecoration: 'underline',
        fontWeight: 700,
        cursor: 'pointer',
    },
    buttons: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'stretch',
    },
    grid: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            gridTemplateColumns: '1fr',
            gridTemplateRows: '1fr max-content',
        },
    },
}))

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />
})

export function BuyProduct(props: BuyProductProps) {
    const { product, open, handleCancel, handleBought, dateType, handleProductCoupon } = props
    const { classes } = useStyles()
    const { session, user } = useAuth()
    const queryClient = useQueryClient()

    const [codeIsForDates, setCodeIsForDates] = useState(false)
    const [discountCodeError, setDiscountCodeError] = useState<string>('')
    const [discountCodeSuccess, setDiscountCodeSuccess] = useState<string>('')
    const [code, setCode] = useState<string>('')
    const [canceledPayment, setCanceledPayment] = useState(false)
    const [discountPercent, setDiscountPercent] = useState(0)
    const { verifyCode } = useCodes(code)

    const createOrder = async () => {
        setCanceledPayment(false)
        return fetch('https://api.namuho.com/v2/checkout/create-order', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${session?.getAccessToken().getJwtToken()}`,
            },

            body: JSON.stringify({
                productId: product?.id,
                code: discountCodeSuccess ? code : undefined,
            }),
        })
            .then((response) => response.json())
            .then((order) => {
                return order.id
            })
    }

    const captureOrder = async (data: any) => {
        return fetch('https://api.namuho.com/v2/checkout/capture-order', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${session?.getAccessToken().getJwtToken()}`,
            },

            body: JSON.stringify({
                orderID: data.orderID,
            }),
        })
            .then((response) => response.json())

            .then((orderData) => {
                queryClient.invalidateQueries([user?.id])
                handleBought(product?.dates || 2)
            })
    }

    const handleCanceledOrder = async (data: any) => {
        setCanceledPayment(true)
    }

    const fetchDiscountCode = async () => {
        const result = await verifyCode()
        const { data } = result
        if (data.code === 'OK') {
            setDiscountCodeError('')
            setCodeIsForDates(false)
            const codeData = data.body as IDiscountCode
            if (codeData.discountPercent && codeData.discountPercent > 0) {
                setDiscountPercent(codeData.discountPercent)
                setDiscountCodeSuccess(`Dein Coupon ist gutlig: ${codeData.discountPercent}% Rabatt`)
            }
            if (codeData.discountPercent === 0 && codeData.dates > 0) {
                setCodeIsForDates(true)
            }
        }
        if (data.code !== 'OK') {
            setDiscountCodeError(data.message)
        }
    }

    const handleCloseModal = () => {
        setCode('')
        setCanceledPayment(false)
        setDiscountPercent(0)
        setDiscountCodeError('')
        setDiscountCodeSuccess('')
        handleCancel()
    }

    return (
        <Dialog fullScreen open={open} onClose={handleCloseModal} TransitionComponent={Transition}>
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleCloseModal} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        {product ? product.name : 'Gutschein'}
                    </Typography>
                </Toolbar>
            </AppBar>
            <Container className={classes.container}>
                <Paper className={classes.paper}>
                    {canceledPayment && (
                        <Alert sx={{ marginBottom: 4 }} severity="error">
                            <Typography variant="h1" component="h2">
                                Etwas is schlecht gelaufen, versuch noch mal, bitte
                            </Typography>
                            <Typography variant="caption">
                                Wenn du Fragen hast, kontaktiere uns unter{' '}
                                <a className="inline-link" href="mailto:info@namuho.com">
                                    info@namuho.com
                                </a>
                            </Typography>
                        </Alert>
                    )}
                    {product && (
                        <Typography variant="h1" component="h1">
                            Du kaufst {product.name}:
                        </Typography>
                    )}
                    <div className={classes.grid}>
                        <div className={classes.content}>
                            {product && (
                                <>
                                    <ul>
                                        <li>
                                            <Trans count={product.dates} i18nKey={`meetings.${dateType}`}>
                                                you have {{ count: product.dates }} dates
                                            </Trans>
                                        </li>
                                        {product.extraDates > 0 && (
                                            <li>
                                                <Trans count={product.extraDates} i18nKey={`meetings.${dateType}`}></Trans> gratis
                                            </li>
                                        )}
                                        {product.bonus && <li>Teilnahme am Gewinnspiel</li>}
                                    </ul>
                                    <Typography variant="h1" component="h2">
                                        Preis:{' '}
                                        {product.discountedPrice && (
                                            <>
                                                {' '}
                                                <span className={classes.priceBeforeDiscount}>
                                                    {((product.price || 0) / 100)?.toLocaleString('de-DE', {
                                                        style: 'currency',
                                                        currency: 'EUR',
                                                    })}
                                                </span>
                                                {((product.discountedPrice || 0) / 100)?.toLocaleString('de-DE', {
                                                    style: 'currency',
                                                    currency: 'EUR',
                                                })}
                                            </>
                                        )}
                                        {!product.discountedPrice &&
                                            ((product.price || 0) / 100)?.toLocaleString('de-DE', {
                                                style: 'currency',
                                                currency: 'EUR',
                                            })}
                                        {discountPercent > 0 && product.discountedPrice && (
                                            <span className={classes.priceWithCoupon}>
                                                Mit Coupon: {discountPercent}% ={' '}
                                                {(((product.discountedPrice || 0) / 100) * (1 - discountPercent / 100))?.toLocaleString('de-DE', {
                                                    style: 'currency',
                                                    currency: 'EUR',
                                                })}
                                            </span>
                                        )}
                                    </Typography>
                                </>
                            )}
                            <Typography variant="body1" component="p">
                                Hast du einen Gutscheincode?
                            </Typography>
                            <div className={classes.discount}>
                                <TextInput label="Gutscheincode" value={code} onChange={(e) => setCode(e.target.value)} />
                                <Button onClick={fetchDiscountCode}>Anwenden</Button>
                            </div>
                            {discountCodeError && (
                                <Typography variant="body1" component="p" color="red">
                                    {discountCodeError}
                                </Typography>
                            )}
                            {discountCodeSuccess && (
                                <Typography variant="body1" component="p" color="green">
                                    {discountCodeSuccess}
                                </Typography>
                            )}
                            {codeIsForDates && (
                                <Typography variant="body1" component="p">
                                    Mit diesem Code erhaltest du keinen prozentualen Rabatt, sondern ein kostenloses Treffen. Wenn du kostenloses
                                    Treffen einlösen möchtest, klick{' '}
                                    <span onClick={handleProductCoupon} className={classes.forDatesLink}>
                                        hier
                                    </span>
                                </Typography>
                            )}
                        </div>
                        <div className={classes.buttons}>
                            <PayPalButtons
                                forceReRender={[discountCodeSuccess]}
                                createOrder={createOrder}
                                onApprove={captureOrder}
                                onCancel={handleCanceledOrder}
                            />
                        </div>
                    </div>
                </Paper>
            </Container>
        </Dialog>
    )
}
